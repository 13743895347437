import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { PrimaryButton } from './PrimaryButton';
import { Form } from 'react-bootstrap';
import { useUser } from '../../redux/hooks';

export const PinCodeModal = () => {
  const [pincode, setPincode] = useState(true);

  const { updatePinCode, user, hideModal } = useUser();

  const onPincodeClick = event => {
    event.preventDefault();
    hideModal();
    updatePinCode(pincode);
  };

  return (
    <Modal
      show={!user.modalHidden || user.address.pinCode.length === 0}
      onHide={() => {}}
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Body>
        <Form
          id="pincodeForm"
          onSubmit={event => {
            onPincodeClick(event);
          }}
        >
          <Form.Group controlId="formGridZip">
            <Form.Control
              required
              className="form-control-lg form-control-sm-md"
              placeholder="Enter your area pin code"
              minLength={6}
              maxLength={6}
              onChange={e => setPincode(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <PrimaryButton type="submit" label="Continue" form="pincodeForm" />
      </Modal.Footer>
    </Modal>
  );
};
