import React from 'react';
import styles from '../Styles/MainPage.module.scss';
import { Items } from '../Assets/Catalogue';
import { useCart } from '../redux/hooks/useCart';
import { Divider } from './Divider/Divider';
import { useNavigate } from 'react-router';
import { ProductCard } from './Utility Components';
import { MobileNav } from './Mobile Screens';
import { CategoriesNavbar } from './Categories';
export const MainPage = () => {
  const { addInCart, getCart, increaseItem, decreaseItem } = useCart();
  let navigate = useNavigate();

  const onAddClick = (item, event) => {
    event.stopPropagation();
    const itemWithVariation = {
      ...item,
      size: item.variation[0].size,
      price: item.variation[0].price,
      discountPrice: item.variation[0].discountPrice,
    }; // Add the first variation
    addInCart(itemWithVariation);
  };

  const handleClick = (id, event) => {
    event.stopPropagation();
    navigate(`/product/${id}`);
  };

  const onDecreaseItem = (id, size, event) => {
    event.stopPropagation();
    decreaseItem(id, size);
  };

  const onIncreaseItem = (id, size, event) => {
    event.stopPropagation();
    increaseItem(id, size);
  };

  const isItemInCart = itemId => {
    let cartItem = getCart.find(item => item.Id === itemId);

    if (cartItem) {
      return { inCart: true, quantity: cartItem.quantity };
    }
    return false;
  };

  const filterByCategory = category => {
    return Items.filter(item => item.category.includes(category));
  };

  const fertilizers = filterByCategory('Fertilizers');
  const soilAdditives = filterByCategory('Soil Additives');

  return (
    <>
      <CategoriesNavbar />
      <div className={styles.mainWrapper}>
        <div id="Fertilizers" className={styles.sectionHeading}>
          <div className={styles.heading}>Fertilizers</div>
          <div className={styles.sectionCount}>{fertilizers.length}</div>
        </div>
        <section>
          {fertilizers.map((item, index) => {
            const variation = item.variation[0];
            return (
              <ProductCard
                onClick={event => {
                  handleClick(item.Id, event);
                }}
                key={index}
                productImage={item.itemImage}
                onAddClick={event => {
                  onAddClick(item, event);
                }}
                inCart={isItemInCart(item.Id, variation.size)}
                productTitle={item.itemName}
                discountedPrice={variation.discountPrice}
                productPrice={variation.price}
                quantity={isItemInCart(item.Id, variation.size)}
                onIncreaseClick={event => {
                  onIncreaseItem(item.Id, variation.size, event);
                }}
                onDecreaseClick={event => {
                  onDecreaseItem(item.Id, variation.size, event);
                }}
              />
            );
          })}
        </section>

        <Divider></Divider>
        <div id="SoilAdditives" className={styles.sectionHeading}>
          <div className={styles.heading}>Soil Additives</div>
          <div className={styles.sectionCount}>{soilAdditives.length}</div>
        </div>
        <section>
          {soilAdditives.map((item, index) => {
            const variation = item.variation[0];
            return (
              <ProductCard
                onClick={event => {
                  handleClick(item.Id, event);
                }}
                key={index}
                productImage={item.itemImage}
                onAddClick={event => {
                  onAddClick(item, event);
                }}
                inCart={isItemInCart(item.Id, variation.size)}
                productTitle={item.itemName}
                discountedPrice={variation.discountPrice}
                productPrice={variation.price}
                quantity={isItemInCart(item.Id, variation.size)}
                onIncreaseClick={event => {
                  onIncreaseItem(item.Id, variation.size, event);
                }}
                onDecreaseClick={event => {
                  onDecreaseItem(item.Id, variation.size, event);
                }}
              />
            );
          })}
        </section>
      </div>

      <MobileNav />
    </>
  );
};
