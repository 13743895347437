import React from 'react';
import { SecondaryButton } from './SecondaryButton';
import styles from '../../Styles/ProductCard.module.scss';

export const ProductCard = ({
  productImage,
  productTitle,
  productPrice,
  isOutOfStock,
  discountedPrice,
  onAddClick,
  productQuantity,
  quantity,
  onIncreaseClick,
  onDecreaseClick,
  inCart,
  onClick,
}) => {
  return (
    <div onClick={onClick} className={styles.cardWrapper}>
      <div className={styles.cardContent}>
        <div className={styles.descAndTitle}>
          <div className={styles.title}>{productTitle}</div>
          {/* <div className={styles.shortDesc}>{productQuantity}</div> */}
        </div>
        <img
          alt={productTitle}
          src={productImage}
          className={styles.itemImage}
        ></img>
      </div>
      <div className={styles.cardFooter}>
        <div className={styles.pricingInformation}>
          <div className={styles.price}>₹{discountedPrice}</div>
          <div className={styles.strikedOutPrice}>₹{productPrice}</div>
          <div className={styles.percentageOffer}>
            {Math.round((discountedPrice / productPrice) * 100 - 100) * -1}%
          </div>
        </div>
        {!inCart.inCart ? (
          <SecondaryButton onClick={onAddClick} text={'ADD'}></SecondaryButton>
        ) : (
          <div className={styles.buttonsContainer}>
            <button
              className={styles.addsubsButton}
              onClick={onDecreaseClick}
              type="button"
            >
              -
            </button>
            {quantity.quantity}
            <button
              className={styles.addsubsButton}
              onClick={onIncreaseClick}
              type="button"
            >
              +
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
