import React, { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { useNavigate, useParams } from 'react-router';
import { getProductById } from '../Assets/Catalogue';
import styles from '../Styles/ProductPage.module.scss';
import { useCart } from '../redux/hooks/useCart';
import { BackButton } from './Utility Components';

export const ProductPage = () => {
  const { productId } = useParams();
  const product = getProductById(parseInt(productId));

  const { addInCart, getCart, increaseItem, decreaseItem } = useCart();

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setCurrentIndex(selectedIndex);
  };

  const [currentVariation, setCurrentVariation] = useState(
    product.variation[0]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isItemInCart = () => {
    let cartItem = getCart.find(
      item =>
        item.Id === parseInt(productId) && item.size === currentVariation.size
    );

    if (cartItem) {
      return { inCart: true, quantity: cartItem.quantity };
    }
    return { inCart: false, quantity: 0 };
  };

  const onAddClick = variation => {
    const itemToAdd = {
      ...product,
      size: variation.size,
      price: variation.price,
      discountPrice: variation.discountPrice,
    };
    addInCart(itemToAdd);
  };

  const item = isItemInCart();
  let navigate = useNavigate();

  return (
    <div className={styles.productPageWrapper}>
      <div className={styles.mobileHeader}>
        <div className={styles.backDiv}>
          <BackButton /> Back to Products
        </div>
      </div>
      <div className={styles.leftSection}>
        <div className={styles.backDiv}>
          <BackButton /> Back to Products
        </div>
        <div className={styles.carouselDiv}>
          <Carousel
            indicators={false}
            activeIndex={currentIndex}
            onSelect={handleSelect}
            style={{
              height: '60vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: ' center',
              width: '100%',
            }}
            variant="dark"
          >
            {product.itemImages.map((img, index) => (
              <Carousel.Item
                key={index}
              >
                <img
                  className={styles.carouselImg}
                  alt={product.itemLongName}
                  src={img}
                ></img>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
        <div className={styles.thumbnailImages}>
          {product.itemImages.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Thumbnail ${index + 1}`}
              className={`${styles.thumbnail} ${
                currentIndex === index ? styles.activeThumbnail : ''
              }`}
              onClick={() => setCurrentIndex(index)}
            />
          ))}
        </div>
      </div>
      <div className={styles.rightSection}>
        <h4 className={styles.productTitle}>
          {`${product.itemLongName}
         (${currentVariation.size})`}
          <img
            className={styles.beeBackground}
            src="https://i.postimg.cc/vHKnMmm2/happy-bee.png"
            alt="happy bee"
          ></img>
        </h4>
        <div className={styles.itemPrice}>
          ₹{currentVariation.discountPrice}
          <div className={styles.strikedOut}>₹{currentVariation.price}</div>
        </div>

        <div className={styles.variantSelect}>
          {product.variation.map((details, index) => (
            <div
              key={index}
              className={`${styles.variationBox} ${
                currentVariation.size === details.size ? styles.isActive : ''
              }`}
              onClick={() => {
                setCurrentVariation(details);
              }}
            >
              <div className={styles.quantity}>{details.size}</div>
              <div className={styles.price}>₹{details.discountPrice}</div>
            </div>
          ))}
        </div>

        <div className={styles.buttonContainer}>
          {!item.inCart ? (
            <button
              className={styles.primaryButton}
              onClick={() => {
                onAddClick(currentVariation);
              }}
            >
              ADD TO CART
            </button>
          ) : (
            <div className={styles.buttonsContainer}>
              <button
                className={styles.addsubsButton}
                onClick={() =>
                  decreaseItem(parseInt(productId), currentVariation.size)
                }
                type="button"
              >
                -
              </button>
              {item.quantity}
              <button
                className={styles.addsubsButton}
                onClick={() =>
                  increaseItem(parseInt(productId), currentVariation.size)
                }
                type="button"
              >
                +
              </button>
            </div>
          )}

          <button
            className={styles.primaryButton}
            onClick={() => {
              navigate('/cart');
            }}
          >
            CHECKOUT
          </button>
        </div>
        <div className={styles.descriptionBlock}>
          <div className={styles.descriptiontitle}>About this item 🐝</div>
          <div className={styles.productFeatures}>
            {product.itemDescription.map((details, index) => (
              <li key={index}>🌼 {details}</li>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.mobileCheckout}>
        <div className={styles.buttonContainer}>
          {!item.inCart ? (
            <button
              className={styles.primaryButton}
              onClick={() => {
                onAddClick(currentVariation);
              }}
            >
              ADD TO CART
            </button>
          ) : (
            <div className={styles.buttonsContainer}>
              <button
                className={styles.addsubsButton}
                onClick={() =>
                  decreaseItem(parseInt(productId), currentVariation.size)
                }
                type="button"
              >
                -
              </button>
              {item.quantity}
              <button
                className={styles.addsubsButton}
                onClick={() =>
                  increaseItem(parseInt(productId), currentVariation.size)
                }
                type="button"
              >
                +
              </button>
            </div>
          )}

          <button
            className={styles.primaryButton}
            onClick={() => {
              navigate('/cart');
            }}
          >
            CHECKOUT
          </button>
        </div>
      </div>
    </div>
  );
};
