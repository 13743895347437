import vermicompostImg from '../Assets/Products/vermicompost.jpg';
import neemcakeImg from '../Assets/Products/neemCake.jpg';
import riceHuskImg from '../Assets/Products/riceHusk.jpg';
import carbonizedRiceHuskImg from '../Assets/Products/carbonizedRiceHusk.jpg';
import cowManureImg from '../Assets/Products/cowManure.jpg';
import perliteImg from '../Assets/Products/perlite.jpg';
import vermiculiteImg from '../Assets/Products/vermiculite.jpg';
import boneMealImg from '../Assets/Products/boneMeal.jpg';
import bonsaiMixImg from '../Assets/Products/bonsaiMix.jpg';
import riverSandImg from '../Assets/Products/riverSand.jpg';
import cinderImg from '../Assets/Products/cinder.jpg';
import fineRiverSandImg from '../Assets/Products/fineRiverSand.jpg';

export const Items = [
  {
    category: ['Fertilizers'],
    itemName: 'Vermicompost (1 Kg)',
    itemDescription: [
      'Our Vermicompost is a high-quality organic compost made from earthworms, ideal for enriching garden soil and promoting vigorous plant growth.',
      'It helps improve soil structure, water retention, and nutrient availability, ensuring your plants receive the essential nutrients they need.',
      'Perfect for all types of plants, including vegetables, flowers, and shrubs, Vermicompost boosts plant health and enhances overall garden productivity.',
      'Our compost is carefully processed to maintain its natural properties, providing a sustainable and eco-friendly solution for your gardening needs.',
      'Available in a convenient 1 kg package, our Vermicompost is easy to handle and store, making it a great addition to any gardening routine.',
    ],
    itemImages: [
      vermicompostImg,
      'https://i.postimg.cc/QNPt9WYL/2.png',
      'https://i.postimg.cc/QxQH2dmR/4.png',
    ],
    outOfStock: false,
    Id: 1,
    itemImage: vermicompostImg,
    quantity: 0,
    itemLongName: 'Vermicompost for Gardening | Gardening Bee',
    variation: [
      { price: 449, size: '1 Kilogram', discountPrice: 199 },
      { price: 599, size: '2 Kilogram', discountPrice: 299 },
      { price: 799, size: '5 Kilogram', discountPrice: 379 },
    ],
  },
  {
    category: ['Fertilizers'],
    itemName: 'Neemcake (1 Kg)',
    itemDescription: [
      'Our Neem Cake is available in an easy-to-use granular form, perfect for mixing into soil for a healthier garden.',
      'Handmade and free from artificial additives, our Neem Cake offers a 100% natural solution for your gardening needs.',
      'Neem Cake is renowned for its ability to combat fungus and pests, providing essential protection for your plants.',
      'Enrich your soil with Neem Cake. It enhances soil quality, promotes healthy plant growth, and improves overall garden productivity.',
      'Our Neem Cake comes in a convenient 1 kg package, ensuring you have the right amount for your gardening needs.',
      'Not just a fertilizer, Neem Cake also helps improve soil texture and fertility over time, making it a valuable addition to your gardening routine.',
      'With its natural properties, Neem Cake contributes to sustainable gardening practices by reducing the need for chemical inputs and promoting a balanced ecosystem.',
    ],
    itemImages: [neemcakeImg],
    outOfStock: false,
    Id: 2,
    itemImage: neemcakeImg,
    quantity: 0,
    itemLongName: 'Neem Cake for Gardening | Gardening Bee',
    variation: [
      { price: 449, size: '1 Kilogram', discountPrice: 249 },
      { price: 599, size: '2 Kilogram', discountPrice: 399 },
      { price: 799, size: '5 Kilogram', discountPrice: 599 },
    ],
  },
  {
    category: ['Soil Additives'],
    itemName: 'Rice Husk (1 Kg)',
    itemDescription: [
      'Rice Husk is an excellent soil additive that improves soil aeration, drainage, and moisture retention for healthier plant roots.',
      'Organic and eco-friendly, it helps enhance soil structure and promotes better root development and plant growth.',
      'Perfect for use in garden beds, pots, and containers, Rice Husk is versatile and easy to incorporate into various soil types.',
      'Our Rice Husk is processed to maintain its natural benefits, making it a valuable addition to any gardening or horticultural practice.',
      'Available in a convenient 1 kg package, it provides a practical solution for optimizing soil health and boosting plant vitality.',
    ],
    itemImages: [riceHuskImg],
    outOfStock: false,
    Id: 3,
    itemImage: riceHuskImg,
    quantity: 0,
    itemLongName: 'Rice Husk for Soil Aeration | Gardening Bee',
    variation: [
      { price: 399, size: '1 Kilogram', discountPrice: 179 },
      { price: 499, size: '2 Kilogram', discountPrice: 299 },
    ],
  },
  {
    category: ['Soil Additives'],
    itemName: 'Carbonized Rice Husk (250 Grams)',
    itemDescription: [
      'Carbonized Rice Husk is a premium soil additive that enhances soil structure, adds essential nutrients, and improves overall soil fertility.',
      'Ideal for use in both garden beds and potted plants, it helps increase soil porosity and promotes better root growth.',
      'Our carbonized rice husk is carefully processed to maximize its benefits, making it an effective and sustainable choice for improving soil health.',
      'Perfect for organic gardening, it supports sustainable practices by reducing waste and enhancing soil quality naturally.',
      'Available in a 250 grams package, it provides a convenient and practical solution for boosting soil health in various gardening applications.',
    ],
    itemImages: [carbonizedRiceHuskImg],
    outOfStock: false,
    Id: 4,
    itemImage: carbonizedRiceHuskImg,
    quantity: 0,
    itemLongName: 'Carbonized Rice Husk for Soil | Gardening Bee',
    variation: [
      { price: 399, size: '250 Grams', discountPrice: 179 },
      { price: 549, size: '500 Grams', discountPrice: 299 },
    ],
  },
  {
    category: ['Fertilizers'],
    itemName: 'Cow Manure (1 Kg)',
    itemDescription: [
      'Cow Manure is a natural and effective organic fertilizer that enhances soil fertility and promotes healthy plant growth.',
      'Rich in essential nutrients, it helps improve soil structure and increases water and nutrient retention for better plant development.',
      'Ideal for use in vegetable gardens, flower beds, and lawns, Cow Manure supports robust plant growth and high crop yields.',
      'Our Cow Manure is carefully processed to ensure its quality and effectiveness, providing a reliable solution for all your gardening needs.',
      'Available in a convenient 1 kg package, it is easy to apply and integrates well with various soil types.',
    ],
    itemImages: [
      cowManureImg,
      'https://i.postimg.cc/y88CGXs5/Cow-manure-1.png',
      'https://i.postimg.cc/Fz42V0TT/Cow-manure-2.png',
    ],
    outOfStock: false,
    Id: 5,
    itemImage: cowManureImg,
    quantity: 0,
    itemLongName: 'Cow Manure for Fertilizing | Gardening Bee',
    variation: [
      { price: 419, size: '1 Kilogram', discountPrice: 199 },
      { price: 599, size: '2 Kilogram', discountPrice: 299 },
      { price: 799, size: '5 Kilogram', discountPrice: 379 },
    ],
  },
  {
    category: ['Soil Additives'],
    itemName: 'Perlite (250 Grams)',
    itemDescription: [
      'Perlite is a lightweight and effective soil additive that increases soil aeration and improves drainage for better root health.',
      'Ideal for use in potting mixes and garden beds, it helps prevent soil compaction and promotes efficient water and nutrient uptake.',
      'Our Perlite is processed to maintain its natural properties, providing a reliable solution for enhancing soil structure and plant growth.',
      'Perfect for both indoor and outdoor gardening, Perlite supports healthy root development and overall plant vitality.',
      'Available in a convenient 250 grams package, it is easy to use and integrates seamlessly into various soil blends.',
    ],
    itemImages: [perliteImg],
    outOfStock: false,
    Id: 6,
    itemImage: perliteImg,
    quantity: 0,
    itemLongName: 'Perlite for Soil Aeration | Gardening Bee',
    variation: [
      { price: 399, size: '250 Grams', discountPrice: 199 },
      { price: 549, size: '500 Grams', discountPrice: 299 },
    ],
  },
  {
    category: ['Soil Additives'],
    itemName: 'Vermiculite (250 Grams)',
    itemDescription: [
      'Vermiculite is an excellent soil additive that improves soil moisture retention, aeration, and overall plant health.',
      'Ideal for seed starting and potting mixes, it helps create a well-draining and nutrient-rich environment for plants.',
      'Our Vermiculite is processed to preserve its natural benefits, providing a sustainable and effective solution for enhancing soil quality.',
      'Perfect for use in both indoor and outdoor gardening, it supports healthy root growth and contributes to robust plant development.',
      'Available in a convenient 250 grams package, it is easy to handle and incorporate into various soil blends.',
    ],
    itemImages: [vermiculiteImg],
    outOfStock: false,
    Id: 7,
    itemImage: vermiculiteImg,
    quantity: 0,
    itemLongName: 'Vermiculite for Soil Moisture | Gardening Bee',
    variation: [
      { price: 399, size: '250 Grams', discountPrice: 199 },
      { price: 549, size: '500 Grams', discountPrice: 249 },
    ],
  },
  {
    category: ['Fertilizers'],
    itemName: 'Bone Meal (1 Kg)',
    itemDescription: [
      'Bone Meal is a rich source of phosphorus that enhances root development and promotes strong, healthy plant growth.',
      'Ideal for use in vegetable gardens, flower beds, and lawns, it helps improve soil fertility and encourages robust root systems.',
      'Our Bone Meal is finely ground and carefully processed to maximize its effectiveness, providing a valuable addition to any gardening routine.',
      'Perfect for both new plantings and established gardens, it supports sustainable gardening practices by enriching the soil naturally.',
      'Available in a convenient 1 kg package, it is easy to apply and integrates well with various soil types.',
    ],
    itemImages: [
      boneMealImg,
      'https://i.postimg.cc/9FsLJLk1/BENIFITS-OF-BONEMEAL.png',
      'https://i.postimg.cc/br1w4yP3/BENIFITS-OF-BONEMEAL-2.png',
      'https://i.postimg.cc/yxRy5ZWt/BENIFITS-OF-BONEMEAL-3.png',
    ],
    outOfStock: false,
    Id: 8,
    itemImage: boneMealImg,
    quantity: 0,
    itemLongName: 'Bone Meal for Gardening - 1 kg | Gardening Bee',
    variation: [
      { price: 449, size: '1 Kilogram', discountPrice: 249 },
      { price: 599, size: '2 Kilogram', discountPrice: 399 },
      { price: 799, size: '5 Kilogram', discountPrice: 599 },
    ],
  },
  {
    category: ['Soil Additives'],
    itemName: 'Bonsai Mix (1 Kg)',
    itemDescription: [
      'Bonsai Mix is specially formulated to support the unique needs of bonsai trees, enhancing soil fertility and plant health.',
      'It provides the perfect balance of nutrients and soil structure to promote healthy growth and vibrant foliage in bonsai plants.',
      'Our mix is designed to improve soil aeration, drainage, and moisture retention, ensuring optimal conditions for bonsai development.',
      'Ideal for both novice and experienced bonsai enthusiasts, it supports sustainable gardening practices by using natural and high-quality ingredients.',
      'Available in a convenient 1 kg package, it is easy to use and integrates seamlessly into your bonsai care routine.',
    ],
    itemImages: [
      bonsaiMixImg,
      'https://i.postimg.cc/7YHkRDTq/Bonsai-Mix.png',
      'https://i.postimg.cc/VNPp8q8m/Bonsai-mix-2.png',
    ],
    outOfStock: false,
    Id: 9,
    itemImage: bonsaiMixImg,
    quantity: 0,
    itemLongName: 'Bonsai Mix for Plant Health | Gardening Bee',
    variation: [
      { price: 429, size: '1 Kilogram', discountPrice: 229 },
      { price: 579, size: '2 Kilogram', discountPrice: 379 },
      { price: 979, size: '5 Kilogram', discountPrice: 579 },
    ],
  },
  {
    category: ['Soil Additives'],
    itemName: 'River Sand (1 Kg)',
    itemDescription: [
      'River Sand is an excellent choice for improving soil drainage and aeration, making it ideal for use in gardening and landscaping.',
      'Perfect for succulents and cacti, it helps create a well-draining environment that prevents waterlogging and root rot.',
      'Our River Sand is washed and screened to remove impurities, ensuring high-quality and consistent performance for various gardening applications.',
      'Ideal for use in garden beds, potted plants, and soil mixes, it supports healthy plant growth and enhances overall soil quality.',
      'Available in a convenient 1 kg package, it provides a practical and effective solution for optimizing soil conditions and promoting robust plant development.',
    ],
    itemImages: [riverSandImg],
    outOfStock: false,
    Id: 12,
    itemImage: riverSandImg,
    quantity: 0,
    itemLongName: 'River Sand for Drainage | Gardening Bee',
    variation: [
      { price: 419, size: '1 Kilogram', discountPrice: 179 },
      { price: 579, size: '2 Kilogram', discountPrice: 279 },
      { price: 779, size: '5 Kilogram', discountPrice: 379 },
    ],
  },
  {
    category: ['Soil Additives'],
    itemName: 'Cinder (1 Kg)',
    itemDescription: [
      'Cinder is an excellent soil additive that enhances drainage and aeration, making it ideal for various gardening needs.',
      'This lightweight volcanic rock helps to improve soil structure, promoting healthy root development and reducing waterlogging.',
      'Cinder is perfect for use in potting mixes, succulent gardens, and as a top dressing for bonsai and other container plants.',
      'Our cinder is naturally sourced and environmentally friendly, supporting sustainable gardening practices.',
      'Available in a convenient 1 kg package, it is easy to mix with other soil components to create the perfect growing environment.',
    ],
    itemImages: [
      cinderImg,
      'https://i.postimg.cc/59Kr0dVP/Cinder-1.png',
      'https://i.postimg.cc/C11NT5zw/Cinder-2.png',
    ],
    outOfStock: false,
    Id: 10,
    itemImage: cinderImg,
    quantity: 0,
    itemLongName: 'Cinder for Enhanced Soil Aeration | Gardening Bee',
    variation: [
      { price: 299, size: '1 Kilogram', discountPrice: 199 },
      { price: 449, size: '2 Kilogram', discountPrice: 349 },
      { price: 749, size: '5 Kilogram', discountPrice: 499 },
    ],
  },
  {
    category: ['Soil Additives'],
    itemName: 'Fine River Sand (1 Kg)',
    itemDescription: [
      'Fine River Sand is perfect for top dressing, improving soil texture, and enhancing soil aeration for healthier plant growth.',
      'Ideal for use in garden beds, potted plants, and seed starting mixes, it helps create a well-draining and nutrient-rich environment.',
      'Our Fine River Sand is carefully processed to ensure its quality and effectiveness, providing a valuable addition to any gardening routine.',
      'Supports better root development and prevents soil compaction, making it an essential component for a successful gardening practice.',
      'Available in a convenient 1 kg package, it is easy to handle and integrates seamlessly into various soil mixes and applications.',
    ],
    itemImages: [fineRiverSandImg],
    outOfStock: false,
    Id: 11,
    itemImage: fineRiverSandImg,
    quantity: 0,
    itemLongName: 'Fine River Sand for Soil Aeration | Gardening Bee',
    variation: [
      { price: 419, size: '1 Kilogram', discountPrice: 179 },
      { price: 579, size: '2 Kilogram', discountPrice: 299 },
      { price: 779, size: '5 Kilogram', discountPrice: 379 },
    ],
  },
];

export function getProductById(id) {
  return Items.find(item => item.Id === id);
}
